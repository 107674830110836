.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-medium);
}

.Type--warning {
  background-color: var(--color-accent-yellow-30);
}

.Type--error {
  background-color: var(--color-fire-red-8);
}
