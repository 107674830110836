.ApiCard-ApiClassification {
  color: var(--color-black-55);
  text-transform: capitalize;
}

.card-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.CodeBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}
